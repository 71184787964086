import Hammer from "hammerjs";

const DEG_TO_RAD = Math.PI / 180;
const FULL_CIRCLE = 360;
const BREAKPOINT = 896;

function resizeRects() {
    var divs = document.querySelectorAll(".card");
    var rectsInner = document.querySelectorAll(".border-rect-inner");

    for (var i = 0; i < divs.length; i++) {
        var div = divs[i];
        var rectInner = rectsInner[i];

        rectInner.setAttribute("width", div.offsetWidth + 10);
        rectInner.setAttribute("height", div.offsetHeight + 10);
        rectInner.setAttribute("x", -5);
        rectInner.setAttribute("y", -5);
    }
}

window.onload = window.onresize = resizeRects;

let lastScrollTop = 0;
const navbar = document.getElementById("navbar");
let navbarHeight = navbar.offsetHeight;

const debounce = (func, delay = 10) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

const handleNavOnScroll = debounce(() => {
    if (navbarHeight / window.innerHeight >= 0.15) {
        let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            navbar.style.top = `-${navbarHeight}px`;
        } else if (lastScrollTop - scrollTop >= 10) {
            navbar.style.top = "0";
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    } else {
        navbar.style.top = "0";
    }
});

window.addEventListener("scroll", handleNavOnScroll);

class CSD {
    constructor(cId, sId, f = false) {
        this.c = document.getElementById(cId);
        this.s = document.getElementById(sId);
        this.f = f;

        window.addEventListener("resize", () => this.d());
        window.addEventListener("DOMContentLoaded", () => {
            this.d();
            setTimeout(() => this.d(), 100);
        });
    }

    pT(p, a = 0, d = 0) {
        const r = (a / 180) * Math.PI;
        return [p[0] + d * Math.cos(r), p[1] + d * Math.sin(r)];
    }

    lL(l) {
        return Math.sqrt(
            Math.pow(l[1][0] - l[0][0], 2) + Math.pow(l[1][1] - l[0][1], 2)
        );
    }

    lA(l) {
        return (
            (Math.atan2(l[1][1] - l[0][1], l[1][0] - l[0][0]) * 180) / Math.PI
        );
    }

    pR(ps, r = 2) {
        if (ps.length < 3) return null;
        let o = "M" + ps[0],
            pr = ps[0];
        for (let i = 1, l = ps.length; i < l; i++) {
            const cu = ps[i],
                a = this.lA([pr, cu]);
            if (i > 1) o += `Q${pr} ${this.pT(pr, a, r)}`;
            o += `L${
                i === ps.length - 1 ? cu : this.pT(pr, a, this.lL([pr, cu]) - r)
            }`;
            pr = cu;
        }
        return o;
    }

    d() {
        while (this.s.firstChild) this.s.firstChild.remove();
        const w = this.c.offsetWidth,
            h = this.c.offsetHeight;
        this.s.setAttributeNS(null, "width", w);
        this.s.setAttributeNS(null, "height", h);

        let p1,
            p2,
            p3,
            p4,
            p = this.f ? [h + 6, 6] : [0, h];
        [p1, p2] = [
            [-15, p[0]],
            [w + 15, p[0]],
        ];
        [p3, p4] = [
            [w, p[1]],
            [0, p[1]],
        ];

        let d1 = this.pR([p1, p4, p3, p2], 8),
            pa = document.createElementNS("http://www.w3.org/2000/svg", "path");
        pa.setAttributeNS(null, "d", d1);
        pa.style.fill = "url(#grad-r)";
        this.s.appendChild(pa);

        let p5,
            p6,
            p7,
            p8,
            p_ = this.f ? [h + 6, 1] : [0, h + 5];
        [p5, p6] = [
            [-20, p_[0]],
            [w + 20, p_[0]],
        ];
        [p7, p8] = [
            [w + 5, p_[1]],
            [-5, p_[1]],
        ];

        let d2 = this.pR([p5, p8, p7, p6], 12),
            pa2 = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
        pa2.setAttributeNS(null, "d", d2);
        pa2.style =
            "stroke:url(#grad-h-glow);fill:none;stroke-width:2;vector-effect:non-scaling-stroke";
        this.s.appendChild(pa2);
    }
}

const nsd = new CSD("nav-shape-container", "nav-shape");
const fsd = new CSD("footer-shape-container", "footer-shape", true);

class TimelineSlide {
    constructor() {
        this.timeline = document.querySelector("#timeline");
        if (!this.timeline) return;

        this.currentCardIndex = 0;
        this.currentScreenSize =
            window.innerWidth > BREAKPOINT ? "big" : "small";
        this.container = document.getElementById("axis");
        this.svg = this.container
            ? this.createSvgElement("svg", { id: "axis-svg", width: "100%" })
            : null;
        this.container.appendChild(this.svg);
        window.addEventListener("resize", () => this.updateAxis());
        this.drawAxis();
        this.initTimeline();
    }

    initTimeline() {
        this.cards = Array.from(document.querySelectorAll(".timeline-card"));
        this.points = Array.from(
            document.querySelectorAll("[id^='tickGroup']")
        );
        this.textContents = this.cards.map((card) =>
            card.querySelector(".card-text-content")
        );
        this.hammertime = new Hammer(this.timeline);

        this.lastWindowSize = window.innerWidth;
        this.autoMoveTimeout;
        this.isTimelineVisible = false;
        this.TIME_LIMIT = 10000;

        this.scales = Array.from({ length: this.cards.length }, (_, i) =>
            Math.pow(0.8, i)
        );
        this.opacities = Array.from({ length: this.cards.length }, (_, i) =>
            Math.pow(0.5, i)
        );
        this.blurs = Array.from({ length: this.cards.length }, (_, i) =>
            i === 0 ? 0 : Math.pow(1.25, i)
        );

        this.hammertime.on("swipeleft swiperight", (ev) => {
            const newIndex =
                ev.type === "swipeleft"
                    ? Math.min(this.currentCardIndex + 1, this.cards.length - 1)
                    : Math.max(this.currentCardIndex - 1, 0);
            this.handleAction(newIndex);
        });

        this.cards.forEach((card, index) =>
            card.addEventListener("click", () => this.handleAction(index))
        );

        window.addEventListener("resize", () => {
            const currentWindowSize = window.innerWidth;
            if (
                this.lastWindowSize <= BREAKPOINT !==
                currentWindowSize <= BREAKPOINT
            ) {
                this.lastWindowSize = currentWindowSize;
                this.updateCards();
            }
        });

        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                clearTimeout(this.autoMoveTimeout);
            } else {
                this.updateCards();
                this.autoMove();
            }
        });

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    this.isTimelineVisible = entry.isIntersecting;
                    if (this.isTimelineVisible) {
                        this.updateCards();
                        this.autoMove();
                    } else {
                        clearTimeout(this.autoMoveTimeout);
                    }
                });
            },
            { threshold: 0.4 }
        );

        observer.observe(this.timeline);
        this.updateCards();
    }

    createSvgElement(type, attributes) {
        let ns = "http://www.w3.org/2000/svg";
        let elem = document.createElementNS(ns, type);
        for (let attr in attributes) {
            elem.setAttribute(attr, attributes[attr]);
        }
        return elem;
    }

    scalePercent(x, domain) {
        return ((x - domain[0]) / (domain[1] - domain[0])) * 100;
    }

    updateAxisTicks(domain, ticks) {
        for (let i = 0; i < ticks; i++) {
            let tickPos = this.scalePercent(i + 1, domain);

            let tickGroup = document.getElementById(`tickGroup${i + 1}`);
            let innerCircle = tickGroup.querySelector(".tl-inner-point");
            let outerCircle = tickGroup.querySelector(".tl-outer-point");

            innerCircle.setAttribute("cx", tickPos + "%");
            outerCircle.setAttribute("cx", tickPos + "%");
        }
    }

    updateMinorTicks(svg, domain) {
        let minorTicksData = this.minorTickData();
        let minorTicksElems = svg.querySelectorAll(".tl-minor-tick");

        for (let i = 0; i < minorTicksData.length; i++) {
            let tickVal = minorTicksData[i];
            let tickPos = this.scalePercent(tickVal, domain);

            minorTicksElems[i].setAttribute("x1", tickPos + "%");
            minorTicksElems[i].setAttribute("x2", tickPos + "%");
        }
    }

    drawAxis() {
        let svg = this.svg;
        let hasDrawn = svg.childNodes.length > 0;
        let domain = [0.5, 5.5];
        let circleRadius = 5;
        let outerCircleRadius = 8;

        if (!hasDrawn) {
            this.drawSpecialTicks(svg, domain, 30, 20);
            this.drawMinorTicks(svg, domain);
            this.drawAxisTicks(svg, domain, 5, circleRadius, outerCircleRadius);
        } else {
            this.updateMinorTicks(svg, domain);
            this.updateAxisTicks(domain, 5);
        }
    }

    drawAxisTicks(svg, domain, ticks, circleRadius, outerCircleRadius) {
        for (let i = 0; i < ticks; i++) {
            let tickGroup = this.createSvgElement("g", {
                id: `tickGroup${i + 1}`,
            });
            let tickPos = parseFloat(
                this.scalePercent(i + 1, domain).toFixed(1)
            );

            let circle0 = this.createSvgElement("circle", {
                class: "tl-hover-area",
                cx: tickPos + "%",
                cy: 20,
                r: outerCircleRadius + 6,
                fill: "transparent",
            });
            tickGroup.appendChild(circle0);

            let circle1 = this.createSvgElement("circle", {
                class: "tl-inner-point",
                cx: tickPos + "%",
                cy: 20,
                r: circleRadius,
            });
            tickGroup.appendChild(circle1);

            let circle2 = this.createSvgElement("circle", {
                class: "tl-outer-point",
                cx: tickPos + "%",
                cy: 20,
                r: outerCircleRadius,
            });
            tickGroup.appendChild(circle2);

            tickGroup.addEventListener("click", () => this.handleAction(i));
            svg.appendChild(tickGroup);
        }
    }

    drawSpecialTicks(svg, domain, startTickHeight, endTickHeight) {
        let specialTicksData = [0.5, 5.5];

        for (let i = 0; i < specialTicksData.length; i++) {
            let tickVal = specialTicksData[i];
            let tickPos = parseFloat(
                this.scalePercent(tickVal, domain).toFixed(1)
            );

            let height = tickVal === 0.5 ? startTickHeight : endTickHeight;
            let y = 20 - height / 2;

            let rect = this.createSvgElement("rect", {
                class: "tl-major-tick",
                x: tickPos + "%",
                y: y,
                width: 3,
                height: height,
            });

            svg.appendChild(rect);
        }
    }

    drawMinorTicks(svg, domain) {
        let minorTicksData = this.minorTickData();
        for (let i = 0; i < minorTicksData.length; i++) {
            let tickVal = minorTicksData[i];
            let tickPos = parseFloat(
                this.scalePercent(tickVal, domain).toFixed(1)
            );

            let line = this.createSvgElement("line", {
                class: "tl-minor-tick",
                x1: tickPos + "%",
                y1: 20 - 5,
                x2: tickPos + "%",
                y2: 20 + 5,
            });

            svg.appendChild(line);
        }
    }

    minorTickData() {
        let minorTicksData = [];
        let minorTicksCountsBigScreen = [3, 10, 15, 20, 25, 15];
        let minorTicksCountsSmallScreen = [2, 4, 6, 8, 10, 12];

        if (this.currentScreenSize === "big") {
            for (let i = 0; i <= 5; i++) {
                let minorTickCount = minorTicksCountsBigScreen[i];
                let range = i === 0 || i === 5 ? 0.5 : 1;
                let increment = range / (minorTickCount + 1);
                let start = i !== 0 ? i : i + range;

                for (let j = 1; j <= minorTickCount; j++) {
                    minorTicksData.push(start + increment * j);
                }
            }
        } else {
            let totalWidth = 5;
            let firstWidth = 2.5 - 0.5 * this.currentCardIndex;
            let lastWidth = 0.5 + 0.5 * this.currentCardIndex;
            let middleWidth = (totalWidth - firstWidth - lastWidth) / 4;

            let widths = [
                firstWidth,
                middleWidth,
                middleWidth,
                middleWidth,
                middleWidth,
                lastWidth,
            ];

            for (let i = 0; i <= 5; i++) {
                let minorTickCount = minorTicksCountsSmallScreen[i];
                let range = widths[i];
                let increment = range / (minorTickCount + 1);
                let start =
                    i !== 0
                        ? widths.slice(0, i).reduce((a, b) => a + b, 0) + 0.5
                        : 0.5;

                for (let j = 1; j <= minorTickCount; j++) {
                    minorTicksData.push(start + increment * j);
                }
            }
        }

        return minorTicksData;
    }

    updateAxis() {
        let newScreenSize = window.innerWidth > BREAKPOINT ? "big" : "small";

        if (newScreenSize !== this.currentScreenSize) {
            this.currentScreenSize = newScreenSize;
            this.svg.innerHTML = "";
            this.drawAxis();
        }
    }

    handleAction(index) {
        clearTimeout(this.autoMoveTimeout);
        this.currentCardIndex = index;
        this.updateCards();
        this.autoMove();

        if (this.currentScreenSize === "small") {
            this.drawAxis();
        }
    }

    autoMove() {
        clearTimeout(this.autoMoveTimeout);

        if (window.innerWidth > BREAKPOINT && this.isTimelineVisible) {
            this.autoMoveTimeout = setTimeout(() => {
                this.currentCardIndex =
                    (this.currentCardIndex + 1) % this.cards.length;
                this.updateCards();
                this.autoMove();
            }, this.TIME_LIMIT);
        }
    }

    updatePoints() {
        this.points.forEach((point, index) => {
            let offsetIndex = index - this.currentCardIndex;

            let circles = point.querySelectorAll("circle");
            circles.forEach((circle) => {
                circle.style.setProperty("--offset", offsetIndex);
            });

            point.classList.toggle(
                "active-point",
                index === this.currentCardIndex
            );
        });
    }

    updateCards() {
        const isSmallScreen = window.innerWidth <= BREAKPOINT;

        this.cards.forEach((card, index) => {
            const offsetIndex = index - this.currentCardIndex;
            const absOffsetIndex = Math.abs(offsetIndex);

            const scale = this.scales[absOffsetIndex];
            const opacity = this.opacities[absOffsetIndex];
            const blur = isSmallScreen ? this.blurs[absOffsetIndex] : 0;

            card.style.setProperty("--offset", offsetIndex);
            card.style.transform = `scale(${scale})`;
            card.style.opacity = opacity;
            card.style.filter = `blur(${blur}px)`;

            this.textContents[index].style.opacity =
                isSmallScreen && index !== this.currentCardIndex ? "0.1" : "1";
        });

        this.points = Array.from(
            document.querySelectorAll("[id^='tickGroup']")
        );
        this.updatePoints();
    }
}

const tl = new TimelineSlide();

class BackgroundShader {
    constructor() {
        this.canvas = document.querySelector("#canvas");
        this.gl = this.canvas.getContext("webgl", { antialias: true });
        this.time = 0;

        this.initializeShaders();
        this.initializeBuffers();
        this.initializeUniforms();
        this.resizeCanvas();
        this.render();
        window.addEventListener("resize", () => this.resizeCanvas());
    }

    initializeShaders() {
        const vertexShaderSource = `
            attribute vec2 position;
            void main() {
                gl_Position = vec4(position, 0.0, 1.0);
            }
        `;

        const fragmentShaderSource = `
        precision highp float;
        uniform vec2 uResolution;
        uniform float uTime;

        float hash21(vec2 p) {
            p = fract(p*vec2(234.34, 435.345));
            p += dot(p, p+23.45);
            return fract(p.x * p.y);
        }
        
        float random(vec2 st) {
            return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
        }
        
        float star(vec2 p, float intensity) {
            vec2 r = sin(vec2(0, 1.57));
        
            p = abs(p * mat2(r, -r.y, r.x)) * mat2(2, 0, 1, 1.7);
            float result = intensity / max(p.x, p.y);
            result *= smoothstep(.0,.05, result);
        
            return result;
        }
        
        vec3 starField(vec2 uv, float t){
            vec3 col = vec3(0);
            
            vec2 gv = fract(uv)-.5;
            vec2 id = floor(uv);
            
            float minSize = 0.2;
            float maxSize = 1.;
            
            for (int y = -1; y<=1; y++){
                for (int x = -1; x<=1; x++){
                    vec2 offset = vec2(x, y);
                    float seed = hash21(id+offset);
                    float randStar1 = fract(seed*12.);
                    float starSize = clamp(fract(seed*123.), minSize, maxSize);
                    float intensity = mix(.0005, .005, starSize);
                    float flickerSpeed = 0.5 + seed * 3.;
                    float flicker = sin(t*flickerSpeed+seed*6.2832)*.5+.5; //(*.5+.5) sin between 0 and 1
                    float star = star(gv-offset-vec2(seed,randStar1)+.5, intensity * flicker);
                    
                    float randStar2 = fract(seed*1234.5);
                    vec3 starColor = sin(vec3(.3,.1,1.)*randStar2*123.2)*.5+.5;
                    vec3 bias = vec3(.9,.2,1.+starSize*3.);
                    starColor = starColor * bias;
                    starColor = mix(starColor, vec3(1.), 0.7);
                    star *= flicker;
                    col += star * starColor * starSize;
                }
            }
            return col;
        }
        
        vec3 softBlueBG(vec2 uv){
            vec3 col = vec3(0);
            vec2 position = vec2(0.5,0.5);
            vec3 blue = vec3(0.08235, 0.08235, 0.1568);
            col += 0.4 * blue * (1.0 - smoothstep(0.0, 0.6, distance(uv + random(uv) * 0.05, position)));
            return col;
        }

        void mainImage(out vec4 fragColor, in vec2 fragCoord)
        {
            vec3 col = vec3(0.066, 0.066, 0.086);
            vec2 uv = fragCoord/uResolution.xy;
            col += softBlueBG(uv) * .75;
            
            uv = (fragCoord.xy * 2.0 - uResolution.xy) / min(uResolution.x, uResolution.y);
            uv *= 6.;
            col += starField(uv, uTime);
            fragColor = vec4(col,1.0);
        }

        void main() {
            mainImage(gl_FragColor, gl_FragCoord.xy);
        }
        `;

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        this.gl.shaderSource(vertexShader, vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        this.gl.shaderSource(fragmentShader, fragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        this.shaderProgram = this.gl.createProgram();
        this.gl.attachShader(this.shaderProgram, vertexShader);
        this.gl.attachShader(this.shaderProgram, fragmentShader);
        this.gl.linkProgram(this.shaderProgram);
        this.gl.useProgram(this.shaderProgram);
    }

    initializeBuffers() {
        this.vertexBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            new Float32Array([-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0]),
            this.gl.STATIC_DRAW
        );
        const positionAttribute = this.gl.getAttribLocation(
            this.shaderProgram,
            "position"
        );
        this.gl.enableVertexAttribArray(positionAttribute);
        this.gl.vertexAttribPointer(
            positionAttribute,
            2,
            this.gl.FLOAT,
            false,
            0,
            0
        );
    }

    initializeUniforms() {
        this.resolutionUniform = this.gl.getUniformLocation(
            this.shaderProgram,
            "uResolution"
        );
        this.timeUniform = this.gl.getUniformLocation(
            this.shaderProgram,
            "uTime"
        );
    }

    resizeCanvas() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        this.gl.uniform2f(
            this.resolutionUniform,
            this.canvas.width,
            this.canvas.height
        );
    }

    render() {
        this.time += 0.025;
        this.gl.uniform1f(this.timeUniform, this.time);
        this.gl.clearColor(0, 0, 0, 1);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT);
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

        requestAnimationFrame(() => this.render());
    }
}

const bgShader = new BackgroundShader();

class OrbitAnimation {
    constructor() {
        if (!document.getElementById("orbit-anim")) return;

        this.svg = document.getElementById("svg");
        this.ellipse = this.svg.getElementById("ellipse");
        this.planet = this.svg.querySelector("#planet");
        this.circle = this.svg.querySelector("#circle");
        this.satellite = this.svg.getElementById("satellite");
        this.arrows = document.getElementById("arrows");

        this.isElementInViewport = false;
        this.isTabVisible = document.visibilityState === "visible";

        this.initializeConstants();
        this.initializeOrbitPoints();
        this.initializeCircularOrbitPoints();
        this.initializeObserver();
        this.initializeVisibilityListener();

        this.requestId = null;
        this.animationIsActive = false;
        this.isCircularAnimPlaying = false;
    }

    initializeConstants() {
        const bbox = this.satellite.getBBox();
        this.satOriginX = bbox.x + bbox.width / 2;
        this.satOriginY = 5;

        this.planetX = parseFloat(this.planet.getAttribute("cx"));
        this.planetY = parseFloat(this.planet.getAttribute("cy"));

        this.ellipseX = parseFloat(this.ellipse.getAttribute("cx"));
        this.ellipseY = parseFloat(this.ellipse.getAttribute("cy"));

        this.EllipseHeight = parseFloat(this.ellipse.getAttribute("ry"));
        this.EllipseWidth = parseFloat(this.ellipse.getAttribute("rx"));
        this.MajorAxis = Math.max(this.EllipseHeight, this.EllipseWidth);
        this.MinorAxis = Math.min(this.EllipseHeight, this.EllipseWidth);
        this.FocusDistance = -Math.sqrt(
            this.MajorAxis * this.MajorAxis - this.MinorAxis * this.MinorAxis
        );
        this.EllipticalEccentricity = this.FocusDistance / this.MajorAxis;
        this.StandardGravity = 25.6;
        this.OrbitalPeriod = Math.sqrt(
            (this.EllipseHeight * this.EllipseHeight * this.EllipseHeight) /
                this.StandardGravity
        );
        this.Resolution = 20;
        this.currentAngle = 0;
        this.currentRadius = this.EllipseHeight + this.FocusDistance;
        this.currentX = this.currentRadius;
        this.currentY = 0;
        this.OrbitRotationAngle = 270;
        this.CosineOfOrbitRotation = this.cos(this.OrbitRotationAngle);
        this.SineOfOrbitRotation = this.sin(this.OrbitRotationAngle);
        this.EllipseCenterX = this.ellipseX;
        this.EllipseCenterY = this.ellipseY;
        this.AreaConstant =
            (FULL_CIRCLE * this.EllipseHeight * this.EllipseWidth) /
            (this.OrbitalPeriod * this.Resolution);
        this.ellipticDuration = 7700;
        this.rotationRadius = Math.abs(
            parseFloat(this.circle.getAttribute("r"))
        );
        this.rotationDuration = 3000;
        this.numPoints = this.rotationDuration;
    }

    initializeOrbitPoints() {
        this.orbitPoints = this.generateOrbitPoints();
    }

    initializeCircularOrbitPoints() {
        this.circularOrbitPoints = [];
        for (let i = 0; i < this.numPoints; i++) {
            const rotationAngle =
                ((i / this.numPoints) * FULL_CIRCLE + 270) % FULL_CIRCLE;
            let point = this.calculateCircularOrbitPoint(rotationAngle);
            this.circularOrbitPoints.push(point);
        }
    }

    initializeObserver() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.isElementInViewport = entry.isIntersecting;
                this.handleAnimation();
            });
        });
        this.observer.observe(this.svg);
    }

    initializeVisibilityListener() {
        document.addEventListener("visibilitychange", () => {
            this.isTabVisible = document.visibilityState === "visible";
            this.handleAnimation();
        });
    }

    handleAnimation() {
        if (this.isElementInViewport && this.isTabVisible) {
            this.startAnimation();
        } else {
            this.stopAnimation();
        }
    }

    generateOrbitPoints() {
        let points = [];
        while (this.currentAngle <= FULL_CIRCLE) {
            const point = this.calculateOrbitPoint();
            points.push(point);
        }
        return points;
    }

    calculateOrbitPoint() {
        for (let i = 0; i < this.Resolution; i++) {
            this.currentAngle +=
                this.AreaConstant / (this.currentRadius * this.currentRadius);
            this.currentRadius =
                (this.MajorAxis *
                    (1 -
                        this.EllipticalEccentricity *
                            this.EllipticalEccentricity)) /
                (1 - this.EllipticalEccentricity * this.cos(this.currentAngle));
        }

        let x1 =
            this.currentRadius * this.cos(this.currentAngle) -
            this.FocusDistance;
        let y1 = this.currentRadius * this.sin(this.currentAngle);

        let calculatedX =
            this.EllipseCenterX +
            x1 * this.CosineOfOrbitRotation -
            y1 * this.SineOfOrbitRotation;

        let calculatedY =
            this.EllipseCenterY +
            x1 * this.SineOfOrbitRotation +
            y1 * this.CosineOfOrbitRotation;

        this.currentX = calculatedX;
        this.currentY = calculatedY;

        let satCenterX = calculatedX - this.satOriginX;
        let satCenterY = calculatedY - this.satOriginY;

        let rotation =
            Math.atan2(
                this.planetY - this.currentY,
                this.planetX - this.currentX
            ) *
                (180 / Math.PI) -
            90;
        return {
            x: satCenterX,
            y: satCenterY,
            rotation: rotation,
        };
    }

    startEllipticalAnim() {
        let startTime;
        this.circle.classList.add("dotted");
        this.ellipse.classList.remove("dotted");
        this.lastTransform = "";

        const animate = (timestamp) => {
            if (!startTime) {
                startTime = timestamp;
            }

            let progress = timestamp - startTime;
            let progressRatio = progress / this.ellipticDuration;

            let pointIndex = Math.floor(
                progressRatio * this.orbitPoints.length
            );

            let point = this.orbitPoints[pointIndex];

            if (point) {
                let transform = `translate(${point.x}, ${point.y}) rotate(${point.rotation}, ${this.satOriginX}, ${this.satOriginY})`;
                if (this.lastTransform !== transform) {
                    this.satellite.setAttribute("transform", transform);
                    this.lastTransform = transform;
                }
            }

            if (progress < this.ellipticDuration - 40) {
                this.requestId = requestAnimationFrame(animate);
            } else {
                if (!this.isCircularAnimPlaying) {
                    this.isCircularAnimPlaying = true;
                    this.startCircularAnim();
                }
            }
        };

        this.requestId = requestAnimationFrame(animate);
    }

    calculateCircularOrbitPoint(angle) {
        let rotationAngleInRad = (angle * Math.PI) / 180;

        let x1 =
            this.planetX + this.rotationRadius * Math.cos(rotationAngleInRad);
        let y1 =
            this.planetY + this.rotationRadius * Math.sin(rotationAngleInRad);

        let rotation = angle + 90;

        let satCenterX = x1 - this.satOriginX;
        let satCenterY = y1 - this.satOriginY;

        return {
            x: satCenterX,
            y: satCenterY,
            rotation: rotation,
        };
    }

    startCircularAnim() {
        let startTime;
        this.ellipse.classList.add("dotted");
        this.circle.classList.remove("dotted");
        this.arrows.classList.add("show-arrows");
        this.lastTransform = "";

        const animate = (timestamp) => {
            if (!startTime) {
                startTime = timestamp;
            }

            let progress = timestamp - startTime;
            let progressRatio = progress / this.rotationDuration;

            let pointIndex = Math.floor(
                progressRatio * this.circularOrbitPoints.length
            );
            let point = this.circularOrbitPoints[pointIndex];

            if (point) {
                let transform = `translate(${point.x}, ${point.y}) rotate(${point.rotation}, ${this.satOriginX}, ${this.satOriginY})`;
                this.satellite.setAttribute("transform", transform);
            }

            if (progress < this.rotationDuration - 20) {
                this.requestId = requestAnimationFrame(animate);
            } else {
                this.arrows.classList.remove("show-arrows");
                this.isCircularAnimPlaying = false;
                this.startEllipticalAnim();
            }
        };

        this.requestId = requestAnimationFrame(animate);
    }

    resetAnimation() {
        this.currentAngle = 0;
        this.currentRadius = this.EllipseHeight + this.FocusDistance;
        this.currentX = this.currentRadius;
        this.currentY = 0;
    }

    startAnimation() {
        if (this.animationIsActive) return;
        this.animationIsActive = true;
        this.isCircularAnimPlaying = true;
        this.startCircularAnim();
    }

    stopAnimation() {
        if (!this.animationIsActive) return;
        this.animationIsActive = false;
        if (this.requestId) {
            cancelAnimationFrame(this.requestId);
            this.requestId = null;
        }
        this.resetAnimation();
    }

    cos(deg) {
        return Math.cos(deg * DEG_TO_RAD);
    }

    sin(deg) {
        return Math.sin(deg * DEG_TO_RAD);
    }
}

const orbitAnim = new OrbitAnimation();

var menuCheckbox = document.getElementById("menu-checkbox");
var menuBtn = document.getElementById("menu-btn");
var closeBtn = document.getElementById("close-btn");

function toggleAriaExpand(expand, keydown) {
    if (keydown) {
        menuCheckbox.checked = expand;
    }

    menuBtn.setAttribute("aria-expanded", expand);
}

menuBtn.addEventListener("click", function () {
    toggleAriaExpand(true, false);
});

menuBtn.addEventListener("keydown", function (e) {
    if (e.key === "Enter" || e.keyCode === 13) {
        toggleAriaExpand(true, true);
    }
});

closeBtn.addEventListener("click", function () {
    toggleAriaExpand(false, false);
});

closeBtn.addEventListener("keydown", function (e) {
    if (e.key === "Enter" || e.keyCode === 13) {
        toggleAriaExpand(false, true);
    }
});
